import React from "react";
import "../styles/gallery.css";
import { PhotoAlbum } from "react-photo-album";
import gal from "../images/gall.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import logo from "../images/logo1.jpg";
import metaData from "./metaData";

function gallery() {
  const photoes = metaData.galleryImages || [];

  return (
    <div id="gallery">
      <div className="gallery-top">
        <div className="nav-logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
      </div>

      <div className="gallery-img">
        <LazyLoadImage className="gal" src={gal} alt="gallery-title-image" />
        <div className="gal-tag" style={{ fontFamily: "MerriWeather,sans-sarif" }}>
          Images
        </div>
      </div>

      <div className="plan-des">
        <div style={{ fontFamily: "MerriWeather,sans-sarif" }}>
          We, WEDDING STORIES, specialize in capturing the special moments of
          your big day in a way that will last a lifetime. Our team of
          experienced photographers understands the importance of your wedding
          day and strives to create images that reflect the beauty, joy, and
          love that you and your partner share.
        </div>
      </div>

      {/* <div id="section-holder">
          <div className="section">
            <Link to='/sections'>
              <LazyLoadImage className="img1" src="https://firebasestorage.googleapis.com/v0/b/weddingstoriess-person.appspot.com/o/home-carousel%2Fl5.jpg?alt=media&token=4c3ff581-4232-4786-8fd8-456c22a32c3a" alt='blog-image'/>
            </Link>
          </div> <div className="section">
            <Link to='/sections'>
              <LazyLoadImage className="img1" src="https://firebasestorage.googleapis.com/v0/b/weddingstoriess-person.appspot.com/o/home-carousel%2Fl5.jpg?alt=media&token=4c3ff581-4232-4786-8fd8-456c22a32c3a" alt='blog-image'/>
            </Link>
          </div> <div className="section">
            <Link to='/sections'>
              <LazyLoadImage className="img1" src="https://firebasestorage.googleapis.com/v0/b/weddingstoriess-person.appspot.com/o/home-carousel%2Fl5.jpg?alt=media&token=4c3ff581-4232-4786-8fd8-456c22a32c3a" alt='blog-image'/>
            </Link>
          </div>
      </div> */}
      <PhotoAlbum layout="masonry" photos={photoes} />
    </div>
  );
}

export default gallery;
