import React from 'react'
import '../styles/footer.css'
function footer() {
  return (
    <>
        <footer>
            <div>@ Wedding Stories {new Date().getFullYear()}. All Rights Reserved.</div>
        </footer>
    </>
  )
}

export default footer