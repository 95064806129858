import React from 'react'
import "../styles/contact.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope , faClock, faPhone , faMapLocation } from '@fortawesome/free-solid-svg-icons';
import { faFacebook , faInstagram , faYoutube } from "@fortawesome/free-brands-svg-icons"


function contact() {
  return (
    <div id='contact'>
      <h1>Contact Us</h1>
      <div className="line"></div>
      <div div className='plan-des'>
      <p>
        Time & Moments both are priceless. To get quality service, Contact us now.
      </p>
      </div>

      <div className='contact-container' style={{ 
      backgroundImage: `url("../images/map.png")` 
    }}>
        <div className='physical-contact'>
          <div className='contact-holder'>
            <span><FontAwesomeIcon icon={faMapLocation} /></span>
            <div ><a href="https://maps.app.goo.gl/DaHjJa47aRrrnDVM9?g_st=iw" target='_blank' rel='noreferrer'>
            <div className='contact-data'>288/1, Shyamaprosad Palli</div>
            <div className='contact-data'>Ranaghat, Nadia, WB - 741201</div></a>
            </div>
          </div>
          <div className='contact-holder'>
            <span><FontAwesomeIcon icon={faClock} /></span>
            <div>
            <div className='contact-data'>Mon - Sat : 11:00am - 9:00pm </div>
            <div className='contact-data'>Sun : OFF </div>
            </div>
          </div>
          <div className='contact-holder'>
            <span><FontAwesomeIcon icon={faPhone} /></span>
            <div>
            <div className='contact-data'><a href='tel:7001385764'>+91 7001385764</a></div>
            </div>
          </div>
          <div className='contact-holder'>
            <span><FontAwesomeIcon icon={faEnvelope} /></span>
            <div>
            <div className='contact-data'><a href='mailto:weddingstoriesgroup@gmail.com'>weddingstoriesgroup@gmail.com</a></div>
            </div>
          </div>
        </div>
        <div className='online-contact'>
          <div className='social'>
            <span><a href="https://www.facebook.com/weddingstoriesfacebook/" target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faFacebook} /></a></span>
            <span><a href="https://www.instagram.com/weddingstoriesinsta/" target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faInstagram} /></a></span>
            <span><a href="https://www.youtube.com/@WeddingStoriesYouTube" target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faYoutube} /></a></span>
          
          <div className='vendor' style={{fontFamily: 'AbrilFatFace,sans-sarif'}}>
            <div>Verified vendor at <a href='https://www.weddingbazaar.com/wedding-photographers/kolkata/weddingstories-131a8cf7-cfe0-4aa0-b75b-efe768e953fb' target='_blank' rel='noreferrer'>WeddingBazaar</a></div>
            <div>Top 5 listed vendor at JustDial</div>
          </div>

          <div id='sc-vendor' style={{fontFamily: 'AbrilFatFace,sans-sarif'}}>
            <div className='company'><a href='https://www.weddingbazaar.com/wedding-photographers/kolkata/weddingstories-131a8cf7-cfe0-4aa0-b75b-efe768e953fb' target='_blank' rel='noreferrer'>WeddingBazaar</a></div>
            <div className='company'><a href='https://jsdl.in/DT-99EULLGT5AR' target='_blank' rel='noreferrer'>JustDial</a></div>
          </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default contact