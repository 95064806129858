import React from 'react';
import '../styles/individualMember.css';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook , faInstagram  } from "@fortawesome/free-brands-svg-icons";

function individualMember(props) {
  const {metaData} = props;
  return (
    <div id="ind-member">
        <div id="dp">
            <LazyLoadImage src={metaData.dp} />
        </div>
        <div className="personal-info">{metaData.role}</div>
        <div className="personal-exp">{metaData.exp}</div>
        <span className="name-member">{metaData.name}</span>
        <div className="social">
            <span><a href={metaData.fb} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faFacebook} /></a></span>
            <span><a href={metaData.insta} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faInstagram} /></a></span>
        </div>
    </div>
  )
}

export default individualMember;