import React from 'react';
import '../styles/nav.css';
import { useState } from 'react';
import logo from "../images/logo1.jpg"
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";


function Navbar() {
    const [toggle, setToggle] = useState(false);
    return (
        <>
        <nav className='nav-holder'>
          <div className='nav-logo'>
            <Link to="/">
              <img src={logo} alt='logo'/>
              {/* <p>Wedding Stories</p> */}
            </Link>
          </div>
          <div className='nav-options'>
              <Link to="/gallery"><div className='nav-ind-options'>Gallery</div></Link>
              <a href="#packages"><div className='nav-ind-options'>Packages</div></a>
              {/* <a href="#blog"><div className='nav-ind-options'>Blog</div></a> */}
              <a href="#about"><div className='nav-ind-options'>About</div></a>
              <a href="#contact"><div className='nav-ind-options'>Contact Us</div></a>
          </div>
          <button className='toggle-logo' onClick={()=> setToggle(!toggle)}><FontAwesomeIcon icon={faBars} /></button>
        </nav>
        {toggle && (
              <nav id='dropdown'>
                <Link to="/gallery" onClick={()=> setToggle(!toggle)}><div className='nav-ind-options'>Gallery</div></Link>
                <a href="#packages" onClick={()=> setToggle(!toggle)}><div className='nav-ind-options'>Packages</div></a>
                {/* <a href="#blog"><div className='nav-ind-options'>Blog</div></a> */}
                <a href="#about" onClick={()=> setToggle(!toggle)}><div className='nav-ind-options'>About</div></a>
                <a href="#contact" onClick={()=> setToggle(!toggle)}><div className='nav-ind-options'>Contact Us</div></a>
              </nav>
        )}
    </>
  )
}

export default Navbar