import React from 'react'
import '../styles/packages.css'
import {  faCheck, faMultiply } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
 

function packages() {
  return (
    <>
      <div id='package'>
        <h1>Plans</h1>
        <div className='line'></div>
        <div className='plan-des'>
          <div style={{fontFamily: 'MerriWeather,sans-sarif'}}>Over 150+ weddings covered, 250+ clients, 30+ cities visited within 4 years. As we know our clients never compromise on the quality, here's the budget friendly plans for your D-Day.</div>
        </div>
        <div id='package-container'>
          <div className='container' id='silver'>
          <div className='pkg-tag'></div>
            <div className='pricing'>
              <div className='category'>Bronze</div>
              <div className='amount'>Rs. 75,000/-</div>
            </div>

            <div className='facilities'>
              <div className='services odd'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>1 Day Pre-wedding Shoot</div>
              </div>
              <div className='services even'>
              <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>3 Days Wedding Coverage</div>
              </div>
              <div className='services odd'>
              <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>Trailer Video</div>
              </div>
              <div className='services even'>
              <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>Full Cinematic Video</div>
              </div>
              <div className='services odd'>
              <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>2 Canvera Albums (25 Sheets)</div>
              </div>
              <div className='services even'>
              <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>Within 120 Days Delivery</div>
              </div>
              <div className='services odd'>
              <span className='red'><FontAwesomeIcon icon={faMultiply}/></span><div>Drone Coverage</div>
              </div>
              <div className='services even'>
              <span className='red'><FontAwesomeIcon icon={faMultiply}/></span><div>Couple Moment Book</div>
              </div>
              <div className='services odd'>
                <span className='red'><FontAwesomeIcon icon={faMultiply}/></span><div>Edited Pre-Wedding Photos With Imaginary Elements</div>
              </div>
              <div className='services even'>
                <span className='red'><FontAwesomeIcon icon={faMultiply}/></span><div>Pre-Wedding Video with VFX</div>
              </div>
              <div className='services odd'>
              <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>Core Team Will Present</div>
              </div>
            </div>
          </div>

          <div className='container' id='golden'>
          <div className='pkg-tag'></div>
            <div className='pricing'>
              <div className='category'>Silver</div>
              <div className='amount'>Rs. 95,000/-</div>
            </div>

            <div className='facilities'>
              <div className='services odd'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>1 Day Pre-wedding Shoot</div>
              </div>
              <div className='services even'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>4 Days Wedding Coverage (Engagement/Aiburobhat)</div>
              </div>
              <div className='services odd'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>Trailer Video</div>
              </div>
              <div className='services even'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>Full Cinematic Video</div>
              </div>
              <div className='services odd'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>2 Canvera Albums (25 Sheets)</div>
              </div>
              <div className='services even'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>Within 120 Days Delivery</div>
              </div>
              <div className='services odd'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>1 Day Drone Coverage</div>
              </div>
              <div className='services even'>
              <span className='red'><FontAwesomeIcon icon={faMultiply}/></span><div>Couple Moment Book</div>
              </div>
              <div className='services odd'>
                <span className='red'><FontAwesomeIcon icon={faMultiply}/></span><div>Edited Pre-Wedding Photos With Imaginary Elements</div>
              </div>
              <div className='services even'>
                <span className='red'><FontAwesomeIcon icon={faMultiply}/></span><div>Pre-Wedding Video with VFX</div>
              </div>
              <div className='services odd'>
              <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>Core Team Will Present</div>
              </div>
            </div>
          </div>

          <div className='container' id='diamond'>

          <div className='pkg-tag popu'>Most Popular</div>

            <div className='pricing'>
              <div className='category'>Gold</div>
              <div className='amount'>Rs. 1,20,000/-</div>
            </div>

            <div className='facilities'>
              <div className='services odd'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>2 Days Pre-wedding Shoot</div>
              </div>
              <div className='services even'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>4 Days Wedding Coverage</div>
              </div>
              <div className='services odd'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>Trailer Video</div>
              </div>
              <div className='services even'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>Full Cinematic Video</div>
              </div>
              <div className='services odd'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>2 Canvera Albums (25 Sheets)</div>
              </div>
              <div className='services even'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>Within 60-90 Days Delivery</div>
              </div>
              <div className='services odd'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>2 Days Drone Coverage</div>
              </div>
              <div className='services even'>
                <span className='red'><FontAwesomeIcon icon={faMultiply}/></span><div>Couple Moment Book</div>
              </div>
              <div className='services odd'>
                <span className='red'><FontAwesomeIcon icon={faMultiply}/></span><div>Edited Pre-Wedding Photos With Imaginary Elements</div>
              </div>
              <div className='services even'>
                <span className='red'><FontAwesomeIcon icon={faMultiply}/></span><div>Pre-Wedding Video with VFX</div>
              </div>
              <div className='services odd'>
              <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>Core Team Will Present</div>
              </div>
            </div>
          </div>

          <div className='container' id='platinum'>
          <div className='pkg-tag exc'>Exclusive</div>

            <div className='pricing'>
              <div className='category'>Diamond</div>
              <div className='amount'>Rs. 1,50,000/-</div>
            </div>

            <div className='facilities'>
              <div className='services odd'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>2 Days Pre-wedding Shoot</div>
              </div>
              <div className='services even'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>4 Days Wedding Coverage</div>
              </div>
              <div className='services odd'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>Trailer Video</div>
              </div>
              <div className='services even'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>Full Cinematic Video</div>
              </div>
              <div className='services odd'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>2 Canvera Albums (30 Sheets)</div>
              </div>
              <div className='services even'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>Within 30 Days Delivery</div>
              </div>
              <div className='services odd'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>3 Days Drone Coverage</div>
              </div>
              <div className='services even'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>Couple Moment Book</div>
              </div>
              <div className='services odd'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>Edited Pre-Wedding Photos With Imaginary Elements</div>
              </div>
              <div className='services even'>
                <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>Pre-Wedding Video with VFX</div>
              </div>
              <div className='services odd'>
              <span className='green'><FontAwesomeIcon icon={faCheck}/></span><div>Core Team & Team Owner Will Present</div>
              </div>
            </div>
          </div>
        </div>
        <div className='plan-des top-padding'>
          <div style={{fontFamily: 'MerriWeather,sans-sarif'}}>* You Can Customize Your Package According To Your Needs Anytime.</div>
        </div>
      </div>
    </>
  )
}

export default packages