import './App.css';
// import ReactGA from 'react-ga';
import Footer from './components/footer.js'
import Home from './components/home';
import Gallery from './components/gallery';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import Modal from './components/modal';

function App() {

  // const setGA = () => {
  //   ReactGA.initialize('UA-xxxxxxx-xx');
  //   ReactGA.pageview("Init page view");
  // };

  // useEffect(() => {
  //   setGA();
  // },[]);

    const [showButton, setShowButton] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
          window.pageYOffset > 400 ? setShowButton(true) : setShowButton(false);
        };
        window.addEventListener('scroll', handleScroll);
    }, []);
    const handleScroll = () => {
      window.scrollTo({top: 0, behavior: 'smooth'});
    };
  

  return (
    <Router>
      <div className="App">
        {/* <Modal /> */}
        {showButton && 
                <div id='backToTop' onClick={handleScroll}>
                  <FontAwesomeIcon icon={faArrowUp} />
                </div>
            }
        <Routes>
          <Route path="/" exact element={<Home/>} />
          <Route path="/gallery" element={<Gallery />} />
        </Routes>
        <Footer />
      </div>
    </Router> 
  );
}

export default App;