import React from "react";
import "../styles/blog.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'


// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import {  faStar } from '@fortawesome/free-solid-svg-icons';

function blog() {
  const reviews = [
    {
      id: 1,
      comment: 'Extremely professional group of young photographers who have the zeal to do great work. Each one of the team has nice creative ideas and skills. I hired them for my wedding in March 2023 and they covered the entire thing starting from Pre-wedding to Reception.I will want to recommend their services to anyone who want their wedding or special occasion to be covered.Cheers  ',
      name: 'Rima Mitra',
    },
    {
      id: 2,
      name: 'Anisha Das',
      comment: 'Just Great Photography & great photographers.My special day became more special for your photography.Thank You... Your behavious is so polite... I am very happy, I was a bit upset with my makeup but your photography covered everything... THANK YOU.  ',
    }
    // Add more reviews as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <div id="blog">
        <div className='testimonial'>
          <h1>Testimonials</h1>
          <div className='line'></div>
            <Slider {...settings}>
              {reviews.map(review => (
                <div id='slider' key={review.id}>
                  <p>{review.comment}</p>
                  <h4>- {review.name}</h4>
                </div>
              ))}
            </Slider>
        </div>
      </div>
    </>
  );
}

export default blog;
