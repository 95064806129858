import React from "react";
import "../styles/about.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faCamera , faImage , faHdd, faClock, faPhone, faLaptop } from '@fortawesome/free-solid-svg-icons';
import metaData from "./metaData";
import IndividualMember from "./individualMember";
 
function about() {
  return (
    <div id="about">
    <h1>Meet Our Team</h1>
    <div className="line"></div>

    <div className="members">
      <div className="col">
        <IndividualMember metaData={metaData.teamDetails.gb}/> 
        <IndividualMember metaData={metaData.teamDetails.shibu}/>
        <IndividualMember metaData={metaData.teamDetails.bratin}/> 
      </div>

      <div className="col">
        <IndividualMember metaData={metaData.teamDetails.santanu}/> 
        <IndividualMember metaData={metaData.teamDetails.saptadip}/>
        <IndividualMember metaData={metaData.teamDetails.ala}/>
      </div>

    </div>


    <div id="sc-members">
      <div className="col">
        <IndividualMember metaData={metaData.teamDetails.gb}/>
        <IndividualMember metaData={metaData.teamDetails.shibu}/>
      </div>

      <div className="col">
        <IndividualMember metaData={metaData.teamDetails.bratin}/> 
        <IndividualMember metaData={metaData.teamDetails.santanu}/>
      </div>

      <div className="col">
        <IndividualMember metaData={metaData.teamDetails.saptadip}/>
        <IndividualMember metaData={metaData.teamDetails.ala}/>
      </div>
    </div>

      <div className="extra">
      <h1>Extra Benefits</h1>
      <div className="line"></div>
      <div div className="plan-des">
        <p>
          We can assure You, We will make your dreams come true and here's why
        </p>
      </div>


      <div id="about-container">
        <div className="benifit-container">
          <div className="icon">
            <FontAwesomeIcon icon={faCamera} />
          </div>
          <div className="desc"><span>Sony A7 Series</span> For Best Quality Photographs</div>
        </div>
        <div className="benifit-container">
          <div className="icon">
          <FontAwesomeIcon icon={faImage} />
          </div>
          <div className="desc"><span>GODOX</span> Light Setup For Amazing Lights</div>
        </div>
        <div className="benifit-container">
          <div className="icon">
          <FontAwesomeIcon icon={faLaptop} />
          </div>
          <div className="desc"><span>Apple M Series</span> Chipset For Editing</div>
        </div>
        <div className="benifit-container">
          <div className="icon">
          <FontAwesomeIcon icon={faClock} />
          </div>
          <div className="desc">Always <span>On Time</span> Delivery</div>
        </div>
        <div className="benifit-container">
          <div className="icon">
          <FontAwesomeIcon icon={faHdd} />
          </div>
          <div className="desc">To Keep Your Data Secure, We Use Only <span>SSDs</span></div>
        </div>
        <div className="benifit-container">
          <div className="icon">
          <FontAwesomeIcon icon={faPhone} />
          </div>
          <div className="desc">We Are Available <span>24/7</span> For Your Service</div>
        </div>
        </div>
      </div>
    </div>
  );
}

export default about;
