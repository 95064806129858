import React from 'react'
import '../styles/home.css'
import { LazyLoadImage } from "react-lazy-load-image-component";
import Packages from './packages'
import Blog from './blog'
import About from './about'
import Contact from './contact'
import Navbar from './navbar';
import decoration from '../images/decoration.svg';

import { PhotoAlbum } from "react-photo-album";
import { Link } from "react-router-dom";

function Home() {

  const photoes = [
    {
      src: "https://firebasestorage.googleapis.com/v0/b/wedding-stories-images.appspot.com/o/bucket-1%2FDSC_2870.jpg?alt=media&token=96a0d833-0b7c-43c6-a4dc-1e1b79ee9fe4",
      width: 300,
      height: 200
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/wedding-stories-images.appspot.com/o/bucket-2%2Fp5.jpg?alt=media&token=657bd0ac-cc52-4a31-b920-f7605d5c472b",
      width: 200,
      height: 300
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/wedding-stories-images.appspot.com/o/bucket-2%2Fp8.jpg?alt=media&token=8ed1d974-680b-4d67-991c-03f44dfd57fc",
      width: 200,
      height: 300
    },
    {
      src: "https://firebasestorage.googleapis.com/v0/b/wedding-stories-images.appspot.com/o/bucket-3%2Fl13.jpg?alt=media&token=d75762bd-52fb-4e25-9c74-2f1ce9d1c3a3",
      width: 300,
      height: 200
    }
  ];
  
  return (
    <>
    <Navbar />
      <section id='home'>
        <div id="home-container">
          <div id='right-col'>
            <div>
              <LazyLoadImage className="img1" src="https://firebasestorage.googleapis.com/v0/b/weddingstoriess-person.appspot.com/o/home-carousel%2Fl5.jpg?alt=media&token=4c3ff581-4232-4786-8fd8-456c22a32c3a" alt='blog-image'/>
              <LazyLoadImage className="img2" src="https://firebasestorage.googleapis.com/v0/b/weddingstoriess-person.appspot.com/o/home-carousel%2FDSC02660m.jpg?alt=media&token=5ce7c886-d97d-42ba-8b4a-eade318ebdbd" alt='blog-image'/>
              {/* <LazyLoadImage className="img3" src="https://firebasestorage.googleapis.com/v0/b/wedding-stories-images.appspot.com/o/bucket-1%2F20.jpg?alt=media&token=08e70df5-dcf7-449e-9932-e3a98576bc53" alt='blog-image'/>
              <LazyLoadImage className="img4" src="https://firebasestorage.googleapis.com/v0/b/wedding-stories-images.appspot.com/o/bucket-1%2F20.jpg?alt=media&token=08e70df5-dcf7-449e-9932-e3a98576bc53" alt='blog-image'/> */}
              {/* <LazyLoadImage className="img5" src="https://firebasestorage.googleapis.com/v0/b/wedding-stories-images.appspot.com/o/bucket-1%2F10.jpg?alt=media&token=57c6034c-b2f5-4c37-87d8-5385c6bf1ec4" alt='blog-image'/> */}
            </div>
          </div>
          <div className='quotes' style={{fontFamily: 'Lobster,sans-sarif'}}>
            <div className='dec-holder'>
              <LazyLoadImage src={decoration} alt="decoration"/> 
            </div>
            <p>Trying to capture your subtle moments</p> 
            <div className='dec-holder'>
              <LazyLoadImage src={decoration} alt="decoration"/></div>
            </div>
        </div>

        <div id='sc-gallery'>
          <h1>Gallery</h1>
          <div className='line'></div>
          <div id='sc-gal-container'>
            <PhotoAlbum layout="masonry" photos={photoes} />
          </div>
          <Link to="/gallery"><div id='show-more'>
            Show More
          </div></Link>
        </div>
      </section>

      <section id='packages'>
        <Packages />
      </section>
      <section id='blog'>
        <Blog />
      </section>
      <section id='about'>
        <About />
      </section>
      <section id='contact'>
        <Contact />
      </section>
    </>
  )
}

export default Home